import React from "react";

export default function Planner({
  titleUppercase,
  subtitleBold,
  time,
  planTimeline,
  color
}) {
  return (
    <div className="">
      <section className="mx-auto max-w-screen-md p-12">
        <div className="flex flex-col text-center w-full mb-8">
          <h2
            className={`text-base text-${color}-700 tracking-widest uppercase font-medium mb-1`}
          >
            {titleUppercase}
          </h2>
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            {subtitleBold}
          </h1>
        </div>
        <div className="mx-auto max-w-screen-md rounded overflow-hidden shadow-lg mt-12">
          <div className="rounded-t-lg bg-gray-200 w-32 p-2 text-center uppercase tracking-wider text-gray-900">
            {" "}
            {time}
          </div>
          <div className={`bg-white border-l-4 border-${color}-500`}>
            {planTimeline.map(plan => (
              <div key={plan.day} className="px-6 py-2">
                <div
                  className={`uppercase tracking-wider text-${color}-700 py-2`}
                >
                  {" "}
                  {plan.day}
                </div>
                <div className="font-bold text-xl mb-2 text-gray-900">
                  {plan.milestone}
                </div>
                <p className="text-gray-700 text-base">{plan.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-12 flex justify-center "></div>
      </section>
    </div>
  );
}
