import React from "react";

export default function MeetInstructors({
  instructors,
  color,
  titleUppercase,
  subtitleBold,
  mentorTitle,
  communityMentors,
}) {
  return (
    <section className="text-gray-600 body-font my-16">
      <div className="flex flex-col text-center w-full mb-8">
        <h2
          className={`text-base text-${color}-700 tracking-widest uppercase font-medium mb-1`}
        >
          {titleUppercase}
        </h2>
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 mb-2">
          {subtitleBold}
        </h1>
      </div>

      <div className="container mx-auto max-w-2xl md:flex">
        {instructors.map((person) => (
          <div key={person.name} className="flex-1">
            <div className="p-4 m-2 border">
              <div className="flex">
                <div className="w-full">
                  <a
                    target="_blank"
                    href={person.link}
                    className="flex justify-center"
                  >
                    <img
                      className="h-24 rounded-full"
                      alt="hero"
                      src={person.image}
                    />
                  </a>
                  <h1 className="text-xl text-gray-800 font-bold p-2 text-center">
                    {person.name}
                  </h1>{" "}
                  <p className="p-2">{person.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8 p-2 text-center">
        <h2 className="text-2xl text-gray-800 mb-4">{mentorTitle}</h2>
        <div className="flex justify-center">
          <div className="-space-x-4 mb-4">
            {communityMentors.map((mentor) => (
              <img
                className="relative z-10 inline object-cover w-16 h-16 border-2 border-white rounded-full"
                src={mentor}
                key={mentor}
                alt="Profile image"
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
