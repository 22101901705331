import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";

import TypedHeader from "components/Courses/LandingComponents/TypedHeader";
import Timeline from "components/Courses/LandingComponents/Timeline";
import ValueImages from "components/Courses/LandingComponents/ValueImages";
import Testimonials from "components/Courses/LandingComponents/Testimonials";
import Planner from "components/Courses/LandingComponents/Planner";
import Price from "components/Courses/LandingComponents/Price";
import FAQ from "components/Courses/LandingComponents/FAQ";
import CallToAction from "components/Courses/LandingComponents/CallToAction";
import LearnCheckmarks from "components/Courses/LandingComponents/LearnCheckmarks.js";
import Banner from "components/Courses/LandingComponents/Banner.js";
import MeetInstructors from "../../components/Courses/LandingComponents/MeetInstructors";

const FigmaCourse = () => {
  const [scholar, setScholar] = useState(false);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get("ref");
    if (ref) {
      sessionStorage.setItem("referralEmail", ref);
      if (ref.includes("scholar")) {
        setScholar(true);
      }
    }
  }, []);
  return (
    <>
      <Helmet
        title="Prototyping with Figma | Enlight"
        meta={[
          {
            name: "description",
            content:
              "Learn to build high-fidelity clickable prototypes in Figma in just two weeks. No experience necessary.              ",
          },
          {
            name: "og:title",
            content: "Prototyping with Figma | Enlight",
          },
          {
            name: "og:description",
            content:
              "Learn to build high-fidelity clickable prototypes in Figma in just two weeks. No experience necessary.              ",
          },
          {
            name: "og:url",
            content: "https://enlight.nyc/courses/figma",
          },
          {
            name: "og:image",
            content: "https://enlight.nyc/img/courses/figma/share.png",
          },
          {
            name: "twitter:title",
            content: "Prototyping with Figma | Enlight",
          },
          {
            name: "twitter:description",
            content:
              "Learn to build high-fidelity clickable prototypes in Figma in just two weeks. No experience necessary.",
          },
          {
            name: "twitter:image",
            content: "https://enlight.nyc/img/courses/figma/share.png",
          },
        ]}
      />
      <TypedHeader
        tag="enrollment closed"
        typeOut={[
          "Entrepreneurs,",
          "Product Managers,",
          "Creators,",
          "Builders,",
        ]}
        headline="prototype your product"
        description="Learn to build high-fidelity clickable prototypes in Figma in just two weeks. No prior experience necessary — join the cohort today."
        button="Join the waitlist"
        buttonDirection="Figma Course"
        enrollment="Cohort registration is currently closed. Join our waitlist to get notified when the next one starts."
        languages={[
          "Wireframing",
          "Prototyping",
          "UX",
          "UI",
          "Interactions",
          "Components",
        ]}
        video="/img/courses/figma/figma-video.mp4"
        gif="https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/3ecd3168-bb89-470b-9f4e-5e0e57bccc80.gif?ClientID=vimeo-core-prod&Date=1614550398&Signature=2cd26c6f9aac3215405721819cf4751c697d738b"
        color="purple"
        cohort="figma"
      />

      <Timeline
        sections={[
          {
            title: "Started",
            subtitle: "March 21st",
            descriptions:
              "Join the waitlist above to be the first to know when the next cohort starts.",
          },
          {
            title: "Time",
            subtitle: "2 week commitment",
            descriptions:
              "~1 hr/day commitment for two weeks. Get immersed in the process.",
          },
          {
            title: "Final Product",
            subtitle: "Your working prototype",
            descriptions:
              "Build a high-fidelity interactive prototype for your mobile or web app.",
          },
          {
            title: "This course is ",
            subtitle: "For Beginners",
            descriptions: "No design experience necessary.",
          },
        ]}
      />

      <ValueImages
        title="An immersive learning experience"
        subtitle="Learn to wireframe and prototype in Figma."
        values={[
          {
            image: "/img/courses/figma/figma-learn.png",
            heading: "Learn by doing",
            caption:
              "We're not your typical online course or bootcamp. You'll prototype a project of your interest by following five live workshops.",
          },
          {
            image: "/img/courses/figma/figma-supporting.png",
            heading: "Stay accountable",
            caption:
              "Build your projects within a community of learners just like you. Get inspired, work with others, ask for feedback, and ship your finished projects for everyone to see!",
          },
          {
            image:
              "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/7c22a4af-853d-4cb7-b61a-e63906618972.gif?ClientID=vimeo-core-prod&Date=1614549951&Signature=16d5cb03183f80d86f631e1fcb1dcf8d3acb846e",
            heading: "Supportive community",
            caption:
              "Once you join our community, we're here for you at every step. Interact with other learners and design amazing applications together.",
          },
        ]}
        color="purple"
      />

      <Banner
        description="Bring your ideas to life in two weeks."
        subtitle="Enlight's unique cohort-based course makes learning fun and easy."
      />

      <Testimonials
        titleUppercase="Join hundreds learning on Enlight"
        color="purple"
        subtitleBold="Hear why learners love the cohort."
        quotes={[
          {
            text:
              "During the course, the learning by doing methodology came to life — this experience pushed me to put learning to practice.",
            personName: "Aashish",
            personPosition: "Data and Tech Strategist",
            personImageLink: "/img/cohort/aashish_headshot.png",
          },
          {
            text:
              "Enlight allows you to feel a sense of accomplishment that you were able to create something on your own.",
            personName: "Ilan",
            personPosition: "Product Manager",
            personImageLink: "/img/cohort/ilan_headshot.png",
          },

          {
            text:
              "My Enlight experience provided me with a tangible skillset which strengthened my ability to advance my career.",
            personName: "Divya",
            personPosition: "Product Manager",
            personImageLink: "/img/cohort/divya_headshot.png",
          },
        ]}
        companyTitle="Learners from the best organizations level up their skills on Enlight."
        companyLogosSize12={[
          "/img/courses/web-development/stripe.png",
          "/img/courses/web-development/microsoft.jpg",
          "/img/courses/web-development/github.png",
        ]}
        companyLogosSize16={[
          "/img/courses/web-development/umich.png",
          "/img/courses/web-development/gt.png",
          "/img/courses/web-development/stanford.png",
        ]}
      />

      <MeetInstructors
        color="purple"
        titleUppercase="Meet your instructor"
        subtitleBold="We've got your back."
        instructors={[
          {
            name: "Zoe Robinson",
            image: "/img/courses/figma/zoe.jpg",
            link: "https://www.zoerob.com/",
            description:
              "Zoe is a UX Designer on a mission to create products that are equally beautiful and usable. Her experience ranges from visual design with AWS, to product design with a social networking startup. She has also worked in advertising and youth entrepreneurship, bringing a business lens to every design project. Currently, she is a second-year graduate student studying Human-Computer Interaction at the University of Michigan, a graduate student instructor, and a UX Design freelancer. With a strong affinity for community building through digital platforms, Zoe's passion lies at the intersection of design, social impact, and accessibility. She is excited to help others turn their ideas into tangible, beautiful prototypes that can be shared with the world!",
          },
        ]}
        mentorTitle="and our community mentors are here to support you."
        communityMentors={[
          "/img/cohort/maxim_headshot.jpg",
          "/img/cohort/samay_headshot.jpg",
          "/img/cohort/atul.jpg",
          "/img/cohort/patrick.jpg",
          "/img/cohort/erica_headshot.jpg",
          "/img/cohort/erin_headshot.png",
          "/img/cohort/oliver_headshot.jpg",
        ]}
      />

      <Planner
        titleUppercase="Two weeks. Perfectly structured."
        color="purple"
        subtitleBold="Build a real prototype."
        time="14 Day Plan"
        planTimeline={[
          {
            day: "Session 1",
            milestone: "Kickoff (90 minutes)",
            description:
              "Introduction to the program – get acquainted with the design process, Figma, and helpful resources. Learn about the mobile and web app design process.",
          },
          {
            day: "Session 2",
            milestone: "Wireframing & User Experience (1 hour)",
            description:
              "Learn more Figma fundamentals and begin building the framework for your prototype. Convert your user journey into a basic wireframe. Learn frames, shapes, grids, buttons, components, and more.",
          },
          {
            day: "Session 3",
            milestone: "Prototype I — Components (1 hour)",
            description:
              "Refine the wireframe and begin transforming your idea into a low-fidelity prototype. Understand material design component best practices and implementation. Introduction to the concept of responsive design in prototyping.",
          },
          {
            day: "Session 4",
            milestone: "Prototype I — User Interface (1 hour)",
            description:
              "Add UI design elements, such as color solutions and typography, to create a consistent brand experience across your prototype.",
          },
          {
            day: "Session 5",
            milestone: "Prototype II — Making it Work (1 hour)",
            description:
              "Refine the prototype look and feel. Learn how to add interactions to make your prototype usable, and presentable as a minimum viable product. Learn frame transitions, overlay transitions, overflow behavior, and more.",
          },
          {
            day: "Session 6",
            milestone: "Demo Day (90 minutes)",
            description:
              "Share your final project with the community! Each cohort member will present their prototype to the group, walking us through their product pitch.",
          },
        ]}
      />

      <LearnCheckmarks
        titleUppercase="Beyond just the basics"
        subtitleBold="We'll go over everything you need to know."
        includedChecklist={[
          "Frames",
          "Buttons",
          "Styles",
          "Images",
          "Shapes",
          "Components",
          "Icons",
          "Overlays",
          "Grid",
          "Typography",
          "Variants",
          "Navigation",
          "Layers",
          "Wireframing",
          "Prototyping",
          "Design process",
        ]}
        color="purple"
      />

      {/* <Price
        titleUppercase="Get ready to level up"
        color="purple"
        subtitleBold="You've got the will, we've got the way."
        cohortInfo={[
          "Cohort #1",
          "Prototyping with Figma",
          "March 21st — April 3rd",
        ]}
        priceInfo={[
          "$179",
          scholar ? "$49" : "$99",
          scholar ? "USD" : "$80 off",
        ]}
        includedChecklist={[
          "Attend 5 live workshops",
          "Build your own prototype",
          "Supportive community",
          "Certificate of completion",
          "Lifetime access",
        ]}
        buttonInfo={["Join now", "Intro to Prototyping", "/courses/figma/join"]}
        scheduleCallInfo={[
          "Want to learn more? Schedule a free 10 min call with our team",
          "https://calendly.com/enlightnyc/meeting",
          "here",
        ]}
      /> */}

      <FAQ
        titleUppercase="Questions? We got you"
        color="purple"
        subtitleBold="Frequently Asked Questions"
        listOfQA={[
          {
            question: "What is Figma?",
            answer:
              "Figma is a free collaborative design tool that is used for wireframing, prototyping, and vector graphics. You can view an example prototype ",
            link:
              "https://www.figma.com/proto/F6Y8g8XPW9EYQsQHeJDDRK/Prototyping-Example?node-id=3%3A0&viewport=592%2C458%2C0.3022543489933014&scaling=scale-down",
            textAfterLink: "here.",
          },
          {
            question: "Do I need prior experience in Figma or design?",
            answer:
              "Nope! This course is designed for beginners and anyone who wants to learn how to prototype their ideas in Figma.",
          },
          {
            question: "Do I need an idea?",
            answer:
              "Not necessarily! We'll provide some sample ideas you can work on and help you brainstorm! :) ",
          },
          {
            question: "What is the time commitment?",
            answer:
              "You can expect to commit an average of 1 hour per day — because of the unique blend of a live community with progress deadlines, you can choose when and how to commit most of your time.",
          },
          {
            question: "When are the live sessions?",
            answer:
              "All live sessions take place at 8:00 PM ET on the Enlight website. However, if you can't make a certain event, no worries! You can always watch a recording. View the event calendar ",
            link:
              "https://calendar.google.com/calendar/u/0/embed?src=b7p6cfernnmvsm2b8kjeutvkkc@group.calendar.google.com&ctz=America/New_York",
            textAfterLink: "here.",
          },
          {
            question: "What will I learn?",
            answer:
              "You will learn how to make wireframes and turn them into prototypes in Figma. After completion, you'll have gained the skills, community, and confidence to bring any idea to life!",
          },
          {
            question: "What do I need to join?",
            answer:
              "Just a laptop and energy to learn! We run the program using the Enlight website and our Discord community.",
          },
          {
            question: "Do you offer scholarships?",
            answer:
              "Yes — if interested, please email (team@tryenlight.com) and let us know why you're interested! Spots for this opportunity are offered on a case by case basis.",
          },
          {
            question: "Got another question?",
            answer:
              "Find us in the Discord community or send a message to team@tryenlight.com with your question. We're happy to answer anything that's on your mind.",
          },
        ]}
      />
      {/* <CallToAction
        subtitleBold="It's time to take the leap."
        description="Learn to build high-fidelity clickable prototypes in Figma in just two weeks. No experience necessary."
        buttonInfo={["Join now", "Figma Course", "/courses/figma/join"]}
        color="purple"
      /> */}
    </>
  );
};

export default FigmaCourse;
