import React from "react";
import { Link } from "gatsby";
import { trackClick } from "constants/helpers";
export default function CallToAction({
  subtitleBold,
  description,
  buttonInfo,
  color
}) {
  return (
    <div className="bg-gray-100">
      <main className="mx-auto max-w-screen-xl">
        <div className="text-center py-16 px-2">
          <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-800 mt-4">
            {subtitleBold}
          </h1>
          <p className="text-xl text-gray-700 mt-2 max-w-lg mx-auto">
            {description}
          </p>

          <div className="mx-auto max-w-xs mt-2">
            <div className="rounded-md shadow">
              <Link
                className={`w-full flex items-center justify-center px-8 py-3 mt-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-${color}-600 hover:bg-${color}-800 focus:outline-none focus:border-${color}-800 focus:shadow-outline-${color} transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10`}
                to={buttonInfo[2]}
                onClick={() =>
                  window.analytics.track("Click", {
                    title: "Join Now",
                    location: "CallToAction",
                    page: window.location.pathname
                  })
                }
              >
                {buttonInfo[0]} &#8250;
               
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
