import React from "react";

export default function Banner({ description, subtitle }) {
  return (
    <div className="relative mx-auto w-full text-center h-full my-8">
      <div className="flex justify-center items-center h-full">
        <img src="/img/courses/figma/figma-collab.png" className="w-full" />
        <div className="text-2xl md:text-6xl font-bold flex items-center absolute top-0 z-10 text-gray-100 text-center h-full">
          {description}
        </div>
      </div>
    </div>
  );
}
