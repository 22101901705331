import React from "react";
import { trackClick } from "constants/helpers";
import { Link } from "gatsby";

export default function Price({
  titleUppercase,
  subtitleBold,
  cohortInfo,
  priceInfo,
  includedChecklist,
  buttonInfo,
  scheduleCallInfo,
  color,
}) {
  return (
    <div className="bg-white">
      <section className="mx-auto max-w-screen-xl p-12">
        <div className="flex flex-col text-center w-full mb-8">
          <h2
            className={`text-base text-${color}-700 tracking-widest uppercase font-medium mb-1`}
          >
            {titleUppercase}
          </h2>
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 mb-2">
            {subtitleBold}
          </h1>
        </div>

        <div className="bg-gray-100 p-4 max-w-lg mx-auto rounded text-center">
          <h1 className="text-base tracking-widest uppercase text-gray-700 p-1">
            {cohortInfo[0]}
          </h1>
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 p-1">
            {cohortInfo[1]}
          </h1>
          <h1 className="text-xl font-bold tracking-tight text-gray-700 p-1">
            {cohortInfo[2]}
          </h1>

          <div className="p-2">
            <div className="flex justify-center">
              <h1 className="flex items-center text-3xl tracking-tight text-gray-700 mr-2 line-through">
                {priceInfo[0]}
              </h1>
              <h1 className="text-5xl font-bold tracking-tight text-gray-900">
                {priceInfo[1]}
              </h1>
              <p className="flex items-center block ml-2 text-gray-700 text-xl">
                {priceInfo[2]}
              </p>
            </div>
            <p className="p-2 text-gray-900 tracking-tight ">
              (only a few spots left!)
            </p>
            
          </div>

          <ul className="text-center mx-auto w-64">
            {includedChecklist.map((check) => (
              <li key={check} className="flex items-center text-gray-800 p-1">
                <svg
                  className="h-5 w-5 text-green-600 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  {" "}
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>{" "}
                </svg>
                {check}
              </li>
            ))}
          </ul>

          <div className="mt-6 flex justify-center mb-2">
            <div>
              <div className="rounded-md shadow">
                <Link
                  className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-${color}-700 hover:bg-${color}-800 focus:outline-none focus:border-${color}-800 focus:shadow-outline-${color} transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10`}
                  to={buttonInfo[2]}
                  onClick={() => {
                    // trackClick("price join now button", buttonInfo[1]);
                    window.analytics.track("Click", {
                      title: "Schedule Meeting with Team",
                      location: "Pricing",
                      page: window.location.pathname,
                      pageTitle: document.title
                    });
                  }}
                >
                  {buttonInfo[0]} &#8250;
                </Link>
              </div>
            </div>
          </div>
          <p className="text-gray-700 mt-4 max-w-xs mx-auto">
            {scheduleCallInfo[0]}{" "}
            <a
              target="_blank"
              className="text-blue-700"
              href={scheduleCallInfo[1]}
              onClick={() => {
                window.analytics.track("Click", {
                  title: "Schedule Meeting with Team",
                  location: "Pricing",
                  page: window.location.pathname,
                  pageTitle: document.title
                });
              }}
            >
              {scheduleCallInfo[2]}
            </a>
            .
          </p>
        </div>
      </section>
    </div>
  );
}
