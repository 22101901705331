import React from "react";

export default function LearnCheckmarks({
  titleUppercase,
  subtitleBold,
  includedChecklist,
  color,
}) {
  return (
    <div className="px-6 py-12 bg-gray-100 my-16">
      <div className="flex flex-col text-center w-full mb-8">
        <h2
          className={`text-base text-${color}-700 tracking-widest uppercase font-medium mb-1`}
        >
          {titleUppercase}
        </h2>
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 mb-2">
          {subtitleBold}
        </h1>
      </div>
      <div className="flex">
        <ul className="grid grid-cols-2 sm:grid-cols-4 gap-y-2 gap-x-4 text-center mx-auto">
          {includedChecklist.map((check) => (
            <li key={check} className="flex items-center text-gray-800 p-1">
              <svg
                className={`h-5 w-5 text-${color}-600 mr-1`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                {" "}
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>{" "}
              </svg>
              {check}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
