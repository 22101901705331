import React from "react";

export default function ValueImages({ title, subtitle, values, color }) {
  return (
    <div className="mx-auto max-w-screen-xl py-12 px-8">
      <div className="flex flex-col text-center w-full">
        <h2
          className={`text-base text-${color}-700 tracking-widest uppercase font-medium mb-1`}
        >
          {title}
        </h2>
        <h1 className="text-4xl font-bold tracking-tight text-gray-900">
          {subtitle}
        </h1>
      </div>
      <section className="block lg:flex">
        {values.map(item => (
          <div key={item.heading} className="p-4 flex-1 text-center mt-4">
            <div className="flex justify-center">
              <img className="h-48 shadow rounded" src={item.image} />
            </div>
            <h2 className="text-3xl text-gray-900 p-2 font-bold tracking-tight mt-2">
              {item.heading}
            </h2>
            <p className="max-w-md mx-auto text-gray-700">{item.caption}</p>
          </div>
        ))}
      </section>
    </div>
  );
}
