import React from "react";

export default function Timeline({ sections }) {
  return (
    <div className="bg-gray-100" id="learn">
      <section className="mx-auto max-w-screen-xl sm:px-6 lg:px-8 mt-24">
        <div className="block lg:flex p-4">
          {sections.map(item => (
            <div
              key={item.title}
              className="pl-4 md:pl-0 pr-4 pt-4 pb-4 flex-1"
            >
              <p className="uppercase text-base tracking-wider text-gray-700 pb-2">
                {item.title}
              </p>
              <p className="text-xl tracking-tight text-gray-900 font-bold pb-1">
                {item.subtitle}
              </p>
              <p className="text-base text-gray-700">{item.descriptions}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}