import React, { useState } from "react";
import { trackClick } from "constants/helpers";
import scrollTo from "gatsby-plugin-smoothscroll";
import Typist from "react-typist";
import TypistLoop from "react-typist-loop";

export default function TypedHeader({
  tag,
  typeOut,
  headline,
  description,
  button,
  buttonDirection,
  enrollment,
  languages,
  video,
  color,
  cohort,
}) {
  const [form, setForm] = useState({
    email: "",
    cohort: cohort,
  });
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <main className="relative mx-auto max-w-screen-xl">
      <main className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-8 sm:px-6 md:mt-12 lg:mt-16 lg:px-8 xl:mt-20 z-10">
        <div className="sm:text-center lg:text-left">
          <div
            className={`py-1 bg-${color}-700 hover:bg-${color}-600 items-center text-${color}-100 leading-none rounded-full flex inline-flex mb-2 cursor-pointer`}
            role="alert"
          >
            <span className="flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2">
              {tag}
            </span>
          </div>
        </div>
        <div className="sm:text-center lg:text-left">
          <h2 className="text-4xl tracking-tight leading-10 font-bold text-gray-900 sm:text-5xl sm:leading-none md:text-5xl lg:max-w-2xl ">
            <TypistLoop interval={1000}>
              {typeOut.map((word) => (
                <Typist key={word} avgTypingDelay={75} cursor={{ blink: true }}>
                  <span>{word}</span>
                  <Typist.Backspace
                    cursor={{ blink: true }}
                    count={word.length}
                    delay={1500}
                  />
                </Typist>
              ))}
            </TypistLoop>
            <span className={`text-${color}-600`}>{headline}</span>
          </h2>

          {/* <TypistLoop interval={3000}>
              {[
                'Hello World',
                'Good Morning',
                'Bye',
              ].map(text => <Typist key={text} startDelay={1000}>{text}</Typist>)}
            </TypistLoop> */}

          <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            {description}
          </p>

          <div className="mt-8 sm:mt-4 sm:flex sm:justify-center lg:justify-start w-full">
            <div className="flex">
              <input
                name="email"
                type="email"
                required
                className="ios shadow w-64 outline-none appearance-none p-2 border-none text-base rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:max-w-xs mr-2"
                placeholder="Email"
                onChange={handleInputChange}
                value={form.email}
                disabled={submitted}
              />
              <input className="hidden" type="submit"></input>
              <button
                disabled={submitted}
                type="submit"
                className={`w-64 rounded shadow flex items-center justify-center py-3 px-2 border border-transparent text-base leading-6 font-medium rounded-md text-white ${
                  submitted
                    ? `bg-green-500`
                    : `bg-${color}-500 hover:bg-${color}-400`
                }  focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer`}
                onClick={() => {
                  var re = new RegExp(
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                  );
                  if (form.email != "" && re.test(form.email)) {
                    setSubmitted(true);

                    const data = new FormData();
                    for (const [key, value] of Object.entries(form)) {
                      data.append(key, value);
                    }

                    fetch(
                      "https://script.google.com/macros/s/AKfycbzwNLU0MO5ZotQaF3TxumrU3dPu-X3MeA68MEiCgT-6_ZeV1iMYe3oY8P-RlpeBef9U/exec",
                      { method: "POST", body: data }
                    ).then(() => {
                      setForm({
                        email: "",
                      });
                    });
                  }
                }}
              >
                {submitted ? "✔ We'll be in touch! :) " : <>{button} &#8250;</>}
              </button>
            </div>
          </div>
          {/* <a
                  onClick={() => {
                    // trackClick("top learn button", buttonDirection);
                    scrollTo("#learn");
                    window.analytics.track("Click", {
                      title: "Learn More",
                      location: "Header",
                      page: window.location.pathname,
                      pageTitle: document.title
                    })
                    //console.log("analytics track");
                  }}
                  className={`cursor-pointer w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-${color}-600 hover:bg-${color}-500 focus:outline-none focus:border-${color}-700 focus:shadow-outline-${color} transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10`}
                >
                  {button} &#8250;
                </a> */}

          <div className="text-gray-600 pt-4 max-w-md mx-auto lg:m-0">
            {enrollment}
          </div>

          <div className="lg:max-w-xs max-w-full mt-8 text-gray-600 sm:justify-center lg:justify-start flex flex-wrap">
            {languages.map((language) => (
              <p key={language} className="tracking-widest uppercase mr-2 ">
                {language}
              </p>
            ))}
          </div>
        </div>
      </main>

      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 py-8 px-4 block z-0 mb-2">
        <video
          className="shadow-2xl tilt-2"
          width="750"
          height="500"
          loop={true}
          autoPlay="autoplay"
          muted
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </main>
  );
}
